* {
	margin: 0;
}

body {
	margin: 0;
	/* background-color: #282c34; */
	/* background: radial-gradient(
		1200px 700px at bottom center,
		rgba(120, 120, 120, 1) 0%,
		rgba(0, 0, 0, 1) 100%
	); */
	background: url('./images/PIA19857.jpg') center;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* p {
	padding: 5px;
	font-stretch: ultra-expanded;
	font-weight: bold;
	font-size: 0.75em;
	background: rgba(0, 0, 0, 0.5);
	border: rgba(0, 0, 0, 0.5);
	border-radius: 5px;
} */
